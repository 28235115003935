.head {
  display: flex;
  text-align: center;
  background-color: #f1f1f1;
}
.head div {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #d5d5d5;
}
.sortable_news_box {
  display: flex;
  height: 50px !important;
  line-height: 40px;
  margin: 0 !important;
  background-color: white !important;
  color: blue !important;
  z-index: 1000;
}
.sortable_news_box div {
  width: 310px;
  padding: 5px;
  border: 1px solid #d5d5d5;
}
.sortable_news_box .title_box {
  width: 339px;
  text-align: center;
}
.sortable_news_box .date_box {
  text-align: center;
}
.title-link {
  cursor: pointer;
}