  .login {
    .app-title h1{font-size:20px;padding-bottom:30px;}
    input {
      text-align: center;
      background-color: #ECF0F1;
      border: 2px solid transparent;
      border-radius: 3px;
      font-size: 16px;
      font-weight: 200;
      padding: 10px 0;
      min-width:  250px;
      width: 250px;
      transition: border .5s;
      }
      
      input:focus {
      border: 2px solid #3498DB;
      box-shadow: none;
      }
      
      .btn {
        border: 2px solid transparent;
        background: #3498DB;
        color: #ffffff;
        font-size: 16px;
        line-height: 25px;
        padding: 10px 0;
        text-decoration: none;
        text-shadow: none;
        border-radius: 3px;
        box-shadow: none;
        transition: 0.25s;
        display: block;
        width: 250px;
        margin: 0 auto;
      }
      
      .btn:hover {
        background-color: #2980B9;
      }
  }
  .login-screen {
  background-color: #FFF;
  border-radius: 5px;
  width: 300px;position:absolute;top:calc(50% - 60px);left:50%;transform:translate(-50%, -50%);margin:0;

  }
  
  .app-title {
  text-align: center;
  color: #777;
    h1{
      text-align:center;
    }
  }
  
  .login-form {
  margin:0 25px;
  }
  .control-group {
  margin-bottom: 10px;
  }
  .login-link {
    font-size: 12px;
    color: #444;
    display: block;
    margin-top: 12px;
  }