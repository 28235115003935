body {
  overflow-y: hidden;
}

.header {
  background: #ccc;
  height: 56px;
  table-layout: fixed;
  width: 100%;
  border-bottom: 1px solid #efebeb;
  padding: 6px;
  a {
    position: relative;
    .logoBox {
      top: -2px;
      margin-left: 18px;
    }
  }
  .logoBox {
    width: 176px;
    margin: auto;
    text-align: center;
    position: relative;
    img {
      width: 28%;
      line-height: 20px;
      &:first-of-type {
        width: 32%;
        margin-right: 8px;
      }
    }
  }
  .menuBox {
    display: inline;
    a {
      margin: auto;
      width: 130px;
    }
  }
  .menuFooter {
    margin-top: 10px;
    p {
      display: inline;
      margin-right: 40px;
      font-size: 12px;
      letter-spacing: 1.2px;
    }
    a {
      display: inline;
      margin-right: 20px;
    }
  }
  .item {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
  }
}

.s-header {
  margin-top: 56px;
  height: 100%;
  .sideMenu {
    background: #f9f9f9;
    border-right: 1px solid #efebeb;
    width: 144px;
    height: 1000px;
    a {
      display: block;
      text-align: center;
      width: 100%;
      font-size: 15px;
      padding: 20px;
    }
  }
}

.item {
  &active,
  .active {
    color: #2e5de2;
  }
}

// 종합정보 페이지 , 판매조건, 가격옵션 콘텐츠 메인 페이지
.info_resultwrap {
  margin-top: 60px;
}
.info_searchwrap td {
  text-align: left;
}
.info_tabletop {
  display: flex;
  margin-top:10px;
 // margin-bottom: 20px;
  div {
    display: inline-block;
  }
  div:last-child {
    margin-left: auto;
  }
}
.set_btn {
  margin-left: 5px;
  font-size:12px;
}
.price_btn{width:50px; border:0; min-width:50px;}

.sortable_news_box .title_box span{    
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;
  line-height:30px;
}

// 라인업 추가/수정 페이지
.lineup_table {
  position: relative;
  .lineup_save_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(246, 246, 246, 0.5);
  }
  .lineup_save_badge {
    position: absolute;
    padding: 5px;
    top: 0;
    right: 0;
    color: white;
    z-index: 1;
  }
}
.trim_table {
  position: relative;
  .trim_save_wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(246, 246, 246, 0.5);
  }
  .trim_save_badge {
    position: absolute;
    padding: 5px;
    top: 0;
    right: 0;
    color: white;
    z-index: 1;
  }
}

//종합정보 수정/추가 페이지
.form_tablewrap th,
.form_tablewrap td {
  text-align: left;
}
.file_wrap {
  position: relative;
  display:inline-block;
  height:34px;
  overflow:hidden;
  float:left;
  padding:0;
  margin:10px;
  border:initial;
  .file_input {
    position: absolute;
    top: 0;
    left: 0;
    background:#fff;
  }
  
}
.file_btn {

  text-align:center; 
  border-radius:6px;
  border: solid 1px #17203b;
  padding: 5px 16px;
  cursor: pointer;
  vertical-align:sub;
  //margin-left: 20px;
}
#line_img, #mda_log ,#add_img, #made_logo{
  border-radius:8px;
}
.revised.on {
  display: none;
}
.revised.off {
  display: none;
}

//판매조건 수정/추가 페이지
.nonborder_table {
  border: none;
  tr,
  td {
    border: none;
    input[type="text"] {
      min-width: auto;
      width: 100%;
    }
  }
}

//모델 수정/추가 페이지
.table_file {
  td {
    input[type="file"] {
      min-width: 300px;
    }
    input[type="text"].file_input {
      width: 300px;
      top: 9px;
    }
    .del_file_btn {
      padding: 3px 23px;
    }
  }
}
.sel_tagwrap {
  margin-top: 16px;
  .sel_tag {
    margin-right: 10px;
    padding : 3px;
    a {
      background: #f0f0f0;
      padding: 3px 10px;
    }
  }
}
.car_img_btn{width:100px; height:100px; margin:2px 0 0 5px;}

//운영 - 공통날개광고 페이지
.img_view {
 // width: 500px;
// 191119 윤현준
  li {
    display: inline-block;
    width: 148px;
    height: 148px;
    margin:0 4px;
    border: solid 1px #afafaf;
    background: #ccc;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }
  // li {
  //   display: inline-block;
  //   width: 50%;
  //   height: 250px;
  //   border: solid 1px #afafaf;
  //   a {
  //     width: 100%;
  //     height: 100%;
  //     background: #ccc;
  //     text-align: center;
  //     line-height: 250px;
  //     font-size: 20px;
  //   }
  // }
  li:nth-child(odd) {
    // border-right: none;
  }
  li:nth-last-of-type(-n + 2) {
    // border-top: none;
  }
}
.mini_table {
  width: 30%;
  td {
    text-align: left;
  }
}
.add_table {
  td {
    text-align: left;
    textarea {
      width: 100%;
      height: 100px;
    }
  }
}
.clearwrap {
  display: flex;
  div {
    margin-left: auto;
  }
}
.popWrap.on {
  display: block;
  left: 0;
}
.pop_cont {
  position: absolute;
  width: 60%;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height:calc(100% - 10%);
  overflow-y:auto;
}

.pop_cont{padding:20px;}
.pop_title{margin-bottom: 10px;}
.recomend_news_pop .pop_title p{font-size:20px;}
.pop_title p{display:inline-block;}
.pop_title span{float:right; cursor: pointer; background-color: #17203b; color: #fff; width: 25px; height: 23px; line-height:23px; text-align: center; font-size: 16px;}

.pop_contwrap .head div{width: 33.3333%;border-right:0;border-bottom:0;}
.pop_contwrap .head div:last-of-type{border-right:1px solid #d5d5d5;}
.pop_contwrap .sortable li div{display: inline-block; width:33.3333%; border:1px solid #d5d5d5;padding:10px;text-align:center;border-right:0;border-bottom:0;}
.pop_contwrap .sortable li div:last-of-type{border-right:1px solid #d5d5d5;}
.pop_contwrap .sortable li:last-of-type div{border-bottom:1px solid #d5d5d5;}
.recom_pop{padding:0 10px;}

.adv_file{display:none;}
.file_wrap .file_input.adv_text{position:static}
.adv_btn{vertical-align:middle;}

/*content*/
.choices__inner{
  position:relative;

  .choices__list--single{
    position:absolute;
    top:0;
    height:100%;
    line-height:40px;
  }

}

#description { 
  width: 100%;
}

/*photoGalleryDetail*/
.gall_managewrap{display:flex; justify-content: space-around; flex-wrap: wrap;}
.gall_managewrap>li{display:inline-block; width:18%; height:200px; border:1px solid #ccc; margin:12px 0;}
.gall_managewrap>li label{width:100%; height:100%; position: relative;}
.gall_managewrap>li img { max-width: 100%; min-width: 100%; max-height: 100%; min-height: 100%; position: relative;}
.gall_managewrap>li.selectImage { border: #5a7fe2 3px solid;}
.navyBg[disabled] { background-color: #ccc; cursor: no-drop;}
.flexCol { display: flex; align-items: center; flex-direction: column;
  img { border: 1px #dbdbdb solid; }}
.ReactCrop { margin-bottom: 50px;}
.editorTitle { font-size: 18px; margin: 10px 0;}
/*trimAdd*/
.resources, .specifications{position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:80%; height:80%; border:1px solid #ccc;  overflow-y:auto; display:none; z-index: 3;}
.resources.on, .specifications.on{display:block;}
.resources>p , .specifications>p{display:inline-block; font-size:20px; font-weight:bold; margin-bottom:20px; margin:20px 0 20px 40px;  z-index: 2;}
.resources>span, .specifications>span{float:right; font-size:20px; cursor:pointer; margin:20px 40px 10px 0;}
.resources_table_box{width:100%; height:auto; margin-bottom:20px; display:flex; justify-content:space-around;}
.resources_table{width:46%; height:100%; }
.resources .resources_table{margin:0 40px; float:left; z-index: 2; overflow-x: hidden; }
.popBg {
  background-color: #000;
  opacity: 0;
  position: fixed;
  width: 300%;height: 300%;
  margin-left: -100%;
  margin-top: -100%;
  overflow-x: hidden;
  display: none;
}
.onPop {
  display: block;
}

/*order*/

.order{position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:70%; height:auto;background:#fff; padding:20px; border:1px solid #ccc; display:none;}
.order.on{display:block;}
.order>p{display:inline-block; font-weight:bold; font-size:16px; }
.order>span{font-size:18px; cursor:pointer;}
.brand_btn{
  display:flex; justify-content: center; width:130px; margin:10px auto;
  .btnSave {
    display: none;
    &.on { display: block; }
  }
}
.order a {width:100%;}
.grid {display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start;}
.gridItem {width: 92px; height: 92px; display: flex; align-items: center; justify-content: center; border: 1px solid #ccc; margin-left: -1px; margin-bottom: -1px;}
.gridItem a {text-align: center;}

/*브랜드 판매조건 캘린더*/
.calendar{position:absolute; width:250px; height:180px; background:#fff;  top:20%; left:50%; transform:translate(-40%, -50%); display:none;}
.calendar.on{display:block;}
.calendar th{background:#ffaa00;}
.calendar td>a{width:100%;height:100%;}
.bgf{background-color:#fff;}

/*차량포토뷰어 이미지 팝업*/
.img_pop{position:absolute; width:500px; min-height:500px; height: auto; background:#fff;  top:50%; left:50%; transform:translate(-50%, -50%); padding:20px; border:1px solid #ccc; display:none; z-index: 10;}
.img_pop.on{
  display:block;
  .edit_desc {
    display: none;
    color: gray;
    &.on { display: block; background-color: inherit; }
  }
}
.img_pop>p{display:inline-block; font-weight:bold; font-size:16px; }
.img_pop>span{float:right; font-size:18px; cursor:pointer; margin-right:10px;}
.img_pop .car_img_box{width:100%; min-height:380px; height: auto;}
.img_pop .car_img_box .car_boxs{width:100%; height:auto; display:flex; justify-content:flex-start; flex-wrap:wrap; }
.img_pop .car_img_box .car_boxs>li{width:18%; height:80px; border:1px solid #ccc; margin:6px 0; text-align:center; line-height:80px;}
.img_pop .car_img_box .car_boxs button{width:100px; height:100px;}
.img_pop .car_img_box .car_boxs .car_box_inner {width: 100%; max-height: 520px; display: flex; flex-direction: row; flex-wrap: wrap; overflow: auto; }
.img_delete { position: relative; transform: translate(42px , -6px ); width: 20px !important; height: 20px !important; color: #000; background: #fff; text-align: center; font-weight: bold;}

/*차량추가*/
.car_bd{display:flex;}
.car_bd_li>a{width:100px; line-height:34px; text-align:center;}

/*매체등록 관리 이미지*/
.media_logo{max-width: 160px;margin: 0 auto;}
.media_logo>img{width:100%; height:auto;}

/*공통날개광고*/
.adv_wrap{display:table-cell; float:initial;}

/*판매실적 추가/ 수정*/
.modify_box{width:100%; margin-top:40px;}
.modify{display:inline-block; font-size:18px;}

.content_reset .thumb_list{width:100%;}
.is-hidden{text-indent:-9999px; -webkit-appearance:none;}

.ck { width : 720px;}
.ck-content { min-height: 500px;}


/* tui hidden */
.tui-editor-defaultUI {height: 0 !important;}

.tui-editor-defaultUI-toolbar > .tui-image.tui-toolbar-icons{
  position: absolute !important;
  right : 76px !important;
  top : -558px;
  z-index: 10;
  background-color: #fafafa;
  border: none;
}

.te-toolbar-section {height: 0 !important;}

.tui-editor-defaultUI-toolbar{
  height: 0 !important;
  width: 0 !important;
}

.te-mode-switch-section {display: none !important;}

.tui-popup-wrapper.te-popup-add-image.tui-editor-popup{top : -500px;}

#editSection { height: 0 !important; }

.se-menu-list li button { text-align : center;}


.media_table td{text-align: center;}
.photo_table td{text-align: center;}
.photo_table td div.gall_img{max-width: 260px;margin: 0 auto;}
.photo_table td div.gall_img img{width: 100%;height: auto;}
.react-datepicker__current-month{text-align:center;}

.reactSelect {
  width: 170px;
  &.trim {
    width: auto;
    z-index: 10;
  }
  &.w-500 {
    width: 500px;
  }
}
.lineup_name_select {
  .reactSelect { width: 507px; }
}
.css-1okebmr-indicatorSeparator { width: 0 !important;}
#brandSelect {
  display: flex;
  .reactSelect {
    margin : 0 5px
  }
}
.brandSelect {
  display: flex;
  .reactSelect {
    margin : 0 5px !important;
  }
}
.categoryMaker {
  color : #2e5de2;
  text-decoration: underline;
  cursor: pointer;
}
.division {
  width: 100%;
  height: 1px;
  background-color: #888;
}
#bulkEditButton{
  width:120px;
  height: 45px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.bulkEditButtonOn{
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: #1b52ed;
  color : #fff;
}

.clipboard {
  border-radius: 5px;
  padding: 5px !important;
}
.clipboard:hover {
  border: 1px solid #17203b;
}

.month-picker > .rmp-container {
  left : -5px !important;
  top : 66px !important;
}

.edit2 .month-picker > .rmp-container {
  left : 300px !important;
  top : 66px !important;
}

#displayNone {
  display: none;
}

.displayNone {
  display: none !important;
}

.info_resultwrap table tbody tr td,
.info_resultwrap table thead tr th {
  text-align: center;
}
.info_resultwrap table thead tr:nth-child(1) th {
  text-align: left;
}

.tradeCardWrapper {
  margin-bottom: 40px;
}

.tradeCard {
  border-top: solid 1px #ccc !important;
}

.commentTable {
  th , td { text-align: center;}
  .left { text-align: left; max-height: 62px;}
  .releative { position: relative;}
  .viewAll { width: 52px; height: 30px; border: 1px solid #dbdbdb; margin-left: 10px; text-align: center; font-weight: 500; position: absolute; right: 12px;}
  .viewArea { display: none; flex-direction: column; width: 230px; max-width: 230px; position: absolute; overflow: hidden; height: auto; word-break: break-word; background-color: #fff; 
              margin-top: 15px; padding: 10px; border: 1px solid #dbdbdb; right: 12px;
    button { width: 20px; height: 20px; position: relative; font-weight: 500; text-align: center; border: 1px #dbdbdb solid; align-self: flex-end; justify-self: flex-start; z-index: 9999;}
  }
}

.detailWrapper { margin-bottom: 50px; min-height: 550px; width: 50%; display: flex; justify-content: space-between; margin : 0 auto; margin-top: 50px; 
  .leftWing , .rightWing { height: 100%; min-height: 550px; width: 47.5%; border: 1px #efefef solid; display: flex; flex-direction: column; padding: 15px;}
  .leftWing {
    .logo { width: 50px;}
    .primaryImage { width: 100%;}
    span { font-weight: 500;}
    .modelName { margin-top: 5px; font-size: 22px;}
    .lineUpName { margin-top: 12px; font-size: 14px; margin-bottom: 3px; color : #555555}
    .trimName { margin-top: 2px; font-size: 14px; margin-bottom: 3px; color : #555555}
    .conditionTitle , .optionTitle { margin-top: 12px; font-size: 16px; }
    .priceOptions { display: flex; justify-content: space-between; align-items: center;
      span { color : #555555}
      span:nth-child(1) { width: 70%; }
    }
  }
  .rightWing {min-height: 550px;
    .title { font-weight: 500; font-size: 18px; margin-top: 10px; margin-bottom: 25px; }
    .priceIndicator , .monthlyPriceIndicator {
      display: flex; align-items: center; justify-content: space-between; font-size: 17px; margin-bottom: 17px;
    }
    .monthlyPriceIndicator { 
      margin-bottom: 15px; 
    }
    .firstIndicator { 
      border-top: 2px solid #efefef; padding-top: 17px;
    }
    .totalPrice {
      display: flex; align-items: center; justify-content: space-between; font-size: 17px; padding-top: 25px; border-top: 2px solid #efefef; padding-bottom: 25px;
      .label { font-weight: 500; font-size: 20px;}
      .price { font-weight: 500; font-size: 20px; color: #1b52ed; }
    }
    .totalMonthlyPrice {
      display: flex; align-items: center; justify-content: space-between; font-size: 17px; padding-top: 5px; padding-bottom: 15px;
      .label { font-weight: 500; font-size: 20px;}
      .priceMonthly { font-weight: 500; font-size: 20px; color: #fd6602; }
    
    }
    .guide {
      font-weight: 500; color: #757575;
    }
  }
}