.select_count {
  padding: 10px;
  text-align: right;
}
.select_count span {
  color: red;
}
.sortable_box {
  display: flex;
  height: 70px;
  margin-bottom: 5px;
  background-color: white;
}
.sortable_box:first-child {
  margin-top: 5px;
}
.sortable_box div {
  height: 100%;
}
.sor {
  display: flex;
  align-items: center;
  width: 70px;
  border: 1px solid #d5d5d5;
  border-right: none;
}
.sor * {
  margin: auto;
}
.sor:last-child {
  border-right: 1px solid #d5d5d5;
  border-left: none;
  cursor: pointer;
}
.sortable_box .top {
  background-color: #eaeaea;
}
.sortable_box .subject div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  padding: 0 15px;
  border: 1px solid #d5d5d5;
  border-right: none;
}
.sortable_box .subject div:first-child {
  border-bottom: none;
}
.sortable_box .subject {
  flex-grow: 1;
}
.sortable_box .image_count div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  padding: 0 15px;
  border: 1px solid #d5d5d5;
}
.sortable_box .image_count div:first-child {
  border-bottom: none;
}
.contpop_img {
  display: inline-block;
  margin-left: 10px;
}
.contpop_img .ct_image {
  width: 70px;
  height: 70px;
}
