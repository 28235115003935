.price_option_wrap {
  .title_wrap {
    display: flex;
    align-items: center;
    .title {
      font-size: 20px;
      margin-right: 10px;
    }
    .select {
      display: flex;
      align-items: center;
      div {
        margin: 0 5px;
      }
    }
  }
  .content_wrap {
    margin: 0 40px 0 40px;
    input {
      min-width: inherit;
      margin: auto;
    }
    textarea {
      resize: horizontal;
    }
    .price_option_list {
      min-height: 150px;
      .pol_head {
        display: flex;
        align-items: center;
        background-color: #f1f1f1;
        div {
          flex-grow: 1;
          padding: 10px;
          height: 100%;
          text-align: center;
        }
        div:first-child {
          max-width: 100px;
        }
        div:last-child {
          border-right: none;
        }
      }
      .pol_default {
        min-height: 100px;
        border-top: none;
        .pol_default_inner {
          display: flex;
          align-items: center;
          div {
            flex-grow: 1;
            padding: 10px;
            height: 100%;
            text-align: center;
          }
          div:first-child {
            max-width: 100px;
          }
          div:last-child {
            border-right: none;
          }
        }
        .price_option_child_list {
          .poc_default {
            display: flex;
            align-items: center;
            margin-left: 200px;
            border: none;
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            div {
              flex-grow: 1;
              text-align: center;
              padding: 10px;
            }
            div:first-child {
              position: relative;
              &:before {
                position: absolute;
                content: "";
                top: -5px;
                left: -63px;
                border-left: 1px solid rgba(67, 116, 217, 0.7);
                border-bottom: 1px solid rgba(67, 116, 217, 0.7);
                width: 125px;
                height: 64px;
                transform: translate(-50%, -50%);
              }
            }
            .price_option_sale {
              max-width: inherit;
            }
            .function {
              max-width: 50px;
            }
          }
        }
      }
      li {
        border: 1px solid #ccc;
        .function {
          text-align: center;
          span {
            cursor: pointer;
            margin-right: 2px;
          }
          span:last-child {
            margin-right: 0;
          }
          .remove {
            padding: 0 7px;
            background-color: #F15F5F;
            border: 1px solid #F15F5F;
            color: white;
            border-radius: 50%;
          }
          .add {
            padding: 0 7px;
            background-color: #2F9D27;
            border: 1px solid #2F9D27;
            color: white;
            border-radius: 50%;
          }
          .child_add {
            padding: 0 7px;
            border: 1px solid #4374D9;
            background-color: #4374D9;
            color: white;
            border-radius: 50%;
          }
        }
        .price_option_name {
          max-width: 400px;
          input {
            width: 100%;
          }
        }
        .price_option_sale {
          max-width: 300px;
          span:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.button_wrap {
  margin-top: 5px;
  text-align: right;
}