.title {
    margin-bottom: 0;
}
.sr-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 84px);
    .sr-content-wrapper-inner {
        input[type="file"] {
            display: none;
        }
        .sr-content-head {
            display: flex;
            justify-content: center;

            span {
                font-weight: bold;
                color: #17203b;
            }

            span.info-text {
                color: blue;
            }

            span.error-text {
                color: red;
            }

            svg {
                vertical-align: middle;
                * {
                    color: #17203b;
                }
            }
        }
        .react-datepicker__month-text {
            text-align: center;
        }
        .btn.download-excel {
            height: 80px;
            line-height: 80px;
            padding: 0 20px;
            cursor: pointer;
            background-color: #EAEAEA;
            font-size: 18px;
            color: gray;
            vertical-align: middle;
            svg {
                vertical-align: middle;
                * {
                    color: gray;
                }
            }
        }
        .btn.upload-excel {
            display: inline-block;
            height: 80px;
            line-height: 80px;
            padding: 0 20px;
            background-color: #17203b;
            font-size: 18px;
            color: white;
            cursor: pointer;
            vertical-align: -3px;
            svg {
                vertical-align: middle;
                * {
                    color: white;
                }
            }
        }
    }
}
