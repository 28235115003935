@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Poppins:500");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR&subset=korean");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:500&subset=korean");
@import url("https://cdn.rawgit.com/young-ha/webfont-archive/master/css/Godo.css");

/*@import url(http://fonts.googleapis.com/earlyaccess/notosanskr.css);*/

/*===========================================================================
	RESET
============================================================================*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button,
input {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  text-align:left;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none;
}
img {
  border: none;
  vertical-align: middle;
}
ol,
ul,
li {
  list-style: none;
}
i,
em {
  font-style: normal;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset {
  border: none;
}
button,
input[type="button"] {
  padding: 0;
  margin: 0;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  background: transparent;
}
input {
  margin: 0;
  padding: 0;
  border: 0;
}
.ccc{
  background:#ccc;
}
/*textarea width값 고정*/
.taw{
  width:773px;  
}
textarea {
  resize: none;
}
input,
select,
button,
textarea {
  outline: none;
}
button:focus {
  outline: 0;
}
table {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
* {
  font-family: "Poppins", "Noto Sans KR", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  color: #2d2d2d;
  border-color: #f1f1f1;
}

/*float*/
.floatL {
  float: left;
}
.floatR {
  float: right;
}
.clearfix:after {
  clear: both;
}

/*폰트사이즈*/
.font_small {
  font-size: 12px;
}
.font_mid {
  font-size: 14px;
}
.font_md {
  font-size: 16px;
}
.font_title {
  font-size: 18px;
}
.fontBig {
  font-size: 20px;
}

.redT {
  color: #f00;
}

/*폰트두께*/
.Normal {
  font-weight: normal;
}
.medium {
  font-weight: 500;
}

/*button*/
.btn {
  min-width: 46px;
  min-height: 30px;
  line-height: 30px;
  color: #2d2d2d;
  text-align:center;
  padding:0 5px;
}
.btnLine {
  min-width: 46px;
  min-height: 30px;
  line-height: 30px;
  text-align:center;
  box-sizing: unset;
}
.btn.pointBg,
.btn.navyBg {
  color: #fff;
  min-width:52px;
}
.btn.grayBg {
  padding: 1px;
}
.btnLine.navyBg {
  border: solid 1px #ccc;
  background: #fff;
  color: #17203b;
  padding:0 2px;
}

/*align*/
.leftT {
  text-align: left;
}
.centerT {
  text-align: center;
}
.rightT {
  text-align: right;
}
/*width*/
.wd100{
  width:100%;
}
.wd46{
  width:46%;
}
.wd50{
  width:50%;
}
.wd70p{
  width:70px;
}
/*height*/
.ht100{
  height:100%;
}
/*margin / padding*/
.mt0 {
  margin-top: 0px !important;
}
.mt4 {
  margin-top: 4px;
}
.mt8 {
  margin-top: 8px !important;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mb40 {
  margin-bottom: 40px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.pl0 {
  padding-left: 0 !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr0 {
  padding-right: 0 !important;
}

/*border*/
.bdccc{
  border:1px solid #ccc;
}


/*bg*/
.grayBg {
  background-color: #f1f1f1;
}
.pointBg {
  background-color: #1b52ed;
}
.navyBg {
  background-color: #17203b;
}

/*input*/
input[type="text"],
input[type="number"],
textarea {
  border: solid 1px #d0d0d0;
  border-radius: 8px;
  padding: 5px 10px;
  min-width: 290px;
}
/*on*/
.on{
  background:#f1f1f1;
}


/*select*/
select {
  height: 30px;
  min-width: 150px;
}

/*table*/
table {
  border: solid 1px #ccc;
}
tr {
  border-bottom: solid 1px #ccc;
}
tr:last-child {
  border-bottom: none;
}
th {
  background: #f1f1f1;
  border-right: solid 1px #ccc;
  font-weight: 500;
}
th:last-child {
  border-right: none;
}
tbody {
  text-align: center;
}
td {
  border-right: solid 1px #ccc;
}
th,
td {
  padding: 10px;
}
.caption {
  margin: 30px 0;
}

/*header*/
.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid #dce3ef;
  background-color: white;
}
.header .logo img {
  width: 40px;
  vertical-align: top;
}
.header .logo p {
  display: inline-block;
  margin-left: 10px;
  line-height: 40px;
}
.header .login ul {
  line-height: 40px;
}
.header .login ul li p {
  margin-right: 10px;
}

/*popup*/
.popWrap {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 501;
  display: none;
}
.popBg {
  position:relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.join_confirm {
  width: 20%;
  background-color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popBtn {
  margin-top: 30px;
  text-align: center;
}

/*pagination*/
.pagination {
  text-align: center;
  margin-top: 40px;
}
.pagination ul {
  display: inline-block;
}
.pagination li {
  float: left;
  margin: 4px;
}
.pagination li a {
  display: block;
  width: 30px;
  height: 30px;
  padding: 7px 0;
  border: 1px solid #dce3ef;
  color: #abb8c3;
  line-height: 1;
  text-align:center;
}
.pagination li a.active {
  border-color: #4260e9;
  background-color: #4260e9;
  color: #fff;
}
.pagination li.off {
  display: none;
}

/*wrap*/
.container {
  padding: 20px;
  //min-width: 1024px;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.title {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: solid 1px #e3e3e3;
}

/*pop*/
.class_pop .join_confirm {
  height: auto;
}
.class_pop label {
  display: inline-block;
  width: 32.6%;
}
.class_pop label input {
  vertical-align: middle;
}
.class_pop p {
  display: inline-block;
  width: 80%;
  padding: 2px 0;
  text-align: center;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  margin-left: 6px;
}

/*inline/ inline-block*/
.disi{display:inline-block;}
.disb{display:block;}