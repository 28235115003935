.ReactModal__Content {
    padding: 0 !important;
    border: none !important;
    border-radius: inherit !important;
    .custom-modal-body {
        max-height: 675px;
        padding: 30px;
    }
}

.ReactModal__Overlay {
    background: rgba(0,0,0,.6) !important;
    z-index: 1010;
}