.add-delete-button {
  .uk-button {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 40px;
  }
}

.uk-card-danger {
  border: 2px solid rgba(202, 0, 42, .5);
}

.form-handler-button {
  button {
    width: 60px;
  }
}
