.thumb_list {
  width: 1500px;
  overflow-x: auto;
}
.thumb_list li img {
  width: 150px;
  height: 100px;
  margin-right: 5px;
  cursor: pointer;
}

.thumb_list li img.none {
  display: none;
}
.thumb_list li img.active {
  border: 2px solid #4260e9;
}
.te-tab button:last-child {
  display: none;
}

.content-editor {
  display: none;
  &.on-editor {
    display: block;
  }
}