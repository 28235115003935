body {
  overflow: auto;
}

.container {
  overflow: hidden;
  height: auto;
  margin-left: 144px;
}


div.sideMenu.floatL {
  position: fixed;
}

.uk-input, .uk-select, .uk-textarea {
  padding: 0 10px !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 0 !important;
  min-width: 0 !important;
}

.uk-input:focus, .uk-select:focus, .uk-textarea:focus {
  outline: 0 !important;
  background-color: #fff !important;
  color: #666 !important;
  border-color: #1e87f0 !important;
}

div.uk-card-default {
  border: 1px solid rgba(0,0,0,.2)
}

.uk-icon-color-white {
  * {
    color: white !important;
  }
}

.uk-icon-color-primary {
  * {
    color: #1e87f0 !important;
  }
}

.uk-icon-color-success {
  * {
    color: #32d296 !important;
  }
}

.uk-icon-color-danger {
  * {
    color: #f0506e !important;
  }
}

.uk-text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.uk-child-button-border {
  button {
    border-right: 1px solid #ccc;
    &:last-child {
      border-right: none;
    }
  }
}

.uk-max-height-small {
  max-height: 260.7px;
}

.uk-max-height-medium {
  max-height: 572.5px;
}

.uk-span-tag {
  span:after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 11px;
    background-color: #ccc;
    margin: 0 5px;
    vertical-align: -1px;
  }
  span:last-child:after {
    display: none;
  }
}

.uk-z-index-large {
  z-index: 1500;
}

.uk-cursor-grab {
  cursor: move;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}